html {
  font-family: $font--secondary;
}

body {
  font-size: 18px;

  @media screen and (max-width: $br--mobile) {
    font-size: 16px;
  }
}

p {
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 1em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font--main;
  font-weight: 700;

  & + p {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

h2 {
  font-size: 2em;
  margin-top: 0.60em;
  margin-bottom: 0.60em;
}

h3 {
  font-size: 1.65em;
  margin-top: 0.63em;
  margin-bottom: 0.63em;
}

h4 {
  font-size: 1.5em;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

h5 {
  font-size: 1.35em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

h6 {
  font-size: 1.25em;
  margin-top: 0.90em;
  margin-bottom: 0.90em;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  transition: color 0.165s;
  color: $color--theme;
  text-decoration: none;

  &:hover {
    color: darken( $color--theme, 10);
  }
}

.condensed {
  padding: 0 $padding--base * 2;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: $br--mobile ) {
    max-width: $br--mobile;
  }

  @media screen and (min-width: $br--tab-portrait ) {
    max-width: $br--tab-portrait - $padding--base * 2;
  }

  @media screen and (min-width: $br--tab-landscape ) {
    max-width: $br--tab-landscape - $padding--base * 2;
  }

  @media screen and (min-width: $br--laptop ) {
    max-width: $br--laptop;
  }
}

.condensed--tab-landscape {
  margin: 0 auto;

  @media screen and (max-width: $br--tab-landscape - 1 ) {
    padding: 0 $padding--base * 2;
  }

  @media screen and (min-width: $br--tab-landscape ) {
    max-width: $br--tab-landscape - $padding--base * 2;
  }

  @media screen and (min-width: $br--laptop ) {
    max-width: $br--laptop;
  }
}

.l-branding {
  @media screen and (max-width: $br--tab-landscape - 1 ) {
    max-width: $br--tab-portrait;
    margin: 0 auto;
  }
}

@mixin button {
  transition: all 0.165s;
  padding: $padding--base $padding--base * 2;
  background-color: $color--theme;
  border: 1px solid $color--theme;
  color: $color--white;

  &:hover {
    background-color: $color--white;
    color: $color--theme;
  }
}

.node-type-tip .field--name-field-tip-photos .field__items {
  display: flex;
  margin-top: $padding--base * 4;
}

.node-type-tip .node__content .field__label {
  color: $color--theme;
}

.node-type-tip .node__content .field {
  margin-bottom: $padding--base * 4;
}

// Take from display
// @todo remove altogether.
.location-locations-display {
  display: none;
}

.group-tip {

  .field--name-field-tip-photos {

    img {
      width: 100%;
    }
  }
}

.field--type-entityreference article {
  border-bottom: 1px solid $color--light-gray;
  padding-bottom: 40px;
}

.node__content {
  margin-bottom: $padding--base * 2;
  padding-bottom: $padding--base * 2;
}

.node__container + .links {
  text-align: right;

  .node-readmore {
    @include button;

    a {
      color: $color--white;

      &:hover {
        color: $color--theme;
      }
    }
  }
}

.l-header {
  background-color: $color--gray;
  padding: $padding--base 0;
}

h1 {
  font-family: 'Caveat', cursive;
  font-weight: 100;
  margin: 0;
  padding: $padding--base * 2 0;
}

@media screen and (max-width: $br--tab-landscape - 1) {
  .l-branding {
    text-align: center;
  }
}

@media screen and (min-width: $br--tab-landscape) {
  .l-branding {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.view-id-categories .view-content {
  display: flex;
  justify-content: space-between;
}

.category-container {
  text-align: center;
  width: 96px;
  margin-right: $padding--base * 2;

  &:last-child {
    margin-right: 0;
  }

  a {
    font-family: $font--main;
    color: $color--white;
    font-size: 0.85em;
    text-transform: uppercase;
    font-weight: 500;

    &:hover {
      color: $color--theme;
    }
  }
}


.form-select  {
  option {
    color: $color--black;
  }
}

.l-main {
  margin-top: 20px;
}

.l-content > article > .node__container {
  > .node__submitted {
    height: 163px;
    float: right;
    padding-bottom: 30px;
    padding-left: 30px;
  }
}

.l-region {
  &--branding {
    display: none;

    @include breakpoint(tablet-portrait) {
      display: block;
    }
  }
}

.field--name-field-tip-categories .field__item,
.field--name-field-tip-category .field__item {
  margin-top: 10px;
}


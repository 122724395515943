.tip-info {
    display: flex;
    &__title {

    }

    &__image {
        margin-right: 20px;
    }

    &__description {
        width: 120px;
    }
}

.views-exposed-form-front-page-map-block,
.views-exposed-form-tip-proximity-block-tip-proximity,
.views-exposed-form-article-map-block-article-map {
  label.option {
    font-weight: normal;
    font-size: 12px;
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
  }

  #edit-tid-wrapper,
  #edit-field-tip-category-tid-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    img {
      height: 65px;
      width: 98px;
    }
  }

  input {
    display: none;
  }

  .term-name {
    font-family: "Open Sans Condensed", sans-serif;
    color: $color--white-pure;
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
  }
}

.bef-select-as-radios .form-item:first-child {
  display: none;
}

form#views-exposed-form-tip-proximity-block-tip-proximity,
form#views-exposed-form-front-page-map-block,
form#views-exposed-form-article-map-block-article-map {
  position: relative;

  .ajax-progress.ajax-progress-throbber {
    position: absolute;
    left: -20px;
    top: 0;
  }
}

.bef-checkboxes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  .form-item {
    padding: 10px;
  }
}

.gmap-popup {
  display: flex;
}

.marker-right.views-fieldset {
  margin-left: 20px;
  width: 150px;

  .views-field-title {
    margin-bottom: 10px;
  }
}

#block-views-maps-block {
  padding-bottom: 10px;
  background-color: $color--gray;
}

#block-views-front-page-map-block {
  display: none;

  @include breakpoint(tablet-portrait) {
    display: block;
  }
}


.category {
  display: flex;

  &__item {
    position: relative;
    width: 120px;
    padding: 10px;
    cursor: pointer;
  }

  &__checkbox {
    position: absolute;
    top: 3px;
    height: 15px;
    width: 15px;
    background: white;
    border: 1px solid #2bb672;
    border-radius: 50%;
    top: 0;
    right: 4px;

    img {
      display: none;
    }
  }

  &__checkbox-checked {
    position: absolute;
    background: none;
    border: none;

    img {
      display: block;
    }
  }

  &__title {
    font-family: "Open Sans Condensed", sans-serif;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
  }
}
$font--main: 'Open Sans Condensed', sans-serif;
$font--secondary: 'Open Sans', sans-serif;

$color--black: #000000;
$color--blue: #00B0F0;
$color--white-pure: #fff;
$color--white: $color--white-pure;
$color--gray: #595959;
$color--light-gray: #e5e5e5;
$color--silver: gainsboro;

$color--theme: $color--blue;
$color--text: $color--gray;
$color--hover: darken( $color--blue, 10);
$color--border: $color--silver;

$br--mobile: 540px;
$br--tab-portrait: 768px;
$br--tab-landscape: 1024px;
$br--laptop: 1280px;

$padding--base: 6px;
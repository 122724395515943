/* article blocks that show when map is not present */

.view-article-map  {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    color: white;
    justify-content: center;

    @include breakpoint(tablet-portrait) {
      color: $color--black;
    }
  }

  a {
    color: white;
    font-weight: bold;

    @include breakpoint(tablet-portrait) {
      color: $color--blue;
    }
  }
}

.field--name-field-tip-photos .field__item {
  margin-right: 10px;
}

.article__details.views-fieldset {
  margin-top: -52px;
  padding: 10px 20px;
  background: rgba(0,0,0,0.3);
}


.article__image {
  height: 170px;
  width: 320px;
}

.field.field--name-body{
  min-height: 230px;
}

#gmap-auto1map-gmap0 {
  display: none;

  @include breakpoint(tablet-portrait) {
    display: block;
  }
}

#block-views-article-map-article-no-map {
  @include breakpoint(tablet-portrait) {
    display: none;
  }
}


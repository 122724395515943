/** List and list item theme **/

.list {
  padding-top: $padding--base * 2;
  margin-bottom: $padding--base * 4;
  padding-bottom: $padding--base * 4;

  .view-content {
    @media screen and (min-width: $br--mobile) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (min-width: $br--tab-portrait) {
    padding-bottom: $padding--base * 8;
  }

  @media screen and( min-width: $br--tab-landscape) {
    padding-bottom: $padding--base * 12;
  }
}

.list {
  .views-row {
    padding-bottom: $padding--base * 2;

    @media screen and (min-width: $br--tab-portrait) {
      width: 48.5%;
    }

    @media screen and (min-width: $br--tab-landscape) {
      width: 31.5%;
    }
  }

  @media screen and (min-width: $br--tab-landscape) {
    .view-content:after {
      content: "";
      width: 31.5%;
    }
  }
}

.list .group {
  position: relative;

  img {
    width: 100%;
    height: 236px;
  }
}

.list .group .views-field-title {
  position: relative;
  margin: 0 auto;

  .field-content {
    font-size: 1.25em;
    margin-top: -$padding--base * 3;
    margin-bottom: 0;
    line-height: 1.25;
    margin-left: $padding--base;
    margin-right: $padding--base;
    background-color: $color--white;
    padding: $padding--base;

    @media screen and (min-width: $br--tab-portrait ) {
      margin-left: $padding--base * 4;
      margin-right: $padding--base * 4;
    }

    @media screen and (min-width: $br--tab-landscape ) {
      margin-left: $padding--base * 6;
      margin-right: $padding--base * 6;
    }


    a {
      color: $color--text;

      &:hover {
        color: $color--theme;
      }
    }
  }
}

.list .group__content {
  padding: $padding--base $padding--base * 2;

  .field-content p {
    margin: 0;
  }

  .views-field-view-node {
    margin-top: $padding--base * 3;
    text-align: right;
  }
}

.list .pager {
  margin-top: $padding--base * 4;

  @media screen and (min-width: $br--tab-portrait) {
    margin-top: $padding--base * 8;
  }

  @media screen and( min-width: $br--tab-landscape) {
    margin-top: $padding--base * 12;
  }

  a {
    @include button;
    cursor: pointer;
  }
}

.list-header {
  margin-bottom: $padding--base * 2;
  display: flex;
  align-items: center;

  h2 {
    font-size: 1em;
    padding: $padding--base $padding--base * 2;
    margin: 0;
    margin-right: $padding--base * 2;
  }
}

.list-header .content {
  display: flex;
  align-items: center;
  background-color: $color--gray;
  color: $color--white;
  width: 100%;

  @include breakpoint(tablet-portrait) {
    width: auto;
  }

  &:after {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid $color--gray;
    display: none;
    margin-right: -20px;
    content: "";

    @include breakpoint(tablet-portrait) {
      display: inline-block;
    }
  }
}

.views-exposed-widget.views-submit-button {
  display: none;
}

.l-content--after .form-type-select,
#places .form-type-select {
  position: relative;
  height: 21.5px;
  background: #595959;
  
  &:after {
    content: '';
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
    pointer-events: none;
    
  }
  
  &:before {
    width: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: #595959;
    content: '';
    pointer-events: none;
  }
  
  &:hover {
    &:before {
      background: #008bbd;
    }
  }
  
  select {
    font-size: 14px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    padding-right: 56px;
    padding-left: 15px;
    -moz-appearance: none;
    -webkit-appearance: none;
    color: white;

    // Disable default styling on ff
    -moz-appearance: none;
    
    // Disable ugly ass outline on firefox 
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #fff;
    } 
    
    // Disable default styling on webkit browsers 
    -webkit-appearance: none;
    
    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }
    
    &:focus {  
      border-color: #fff;    
    }
  }
}

#places {
  .places__container  {
    select {
      width: 200px;
    }
  }
}

// IE 9 only 
@media all and (min-width:0\0) and (min-resolution:.001dpcm){
  .form-select{ 
    select {
      padding-right: 0;
    }

    &:after, &:before {
      display: none;
    }
  }
}

.view-display-id-front_articles {
  #edit-field-tip-category-tid-wrapper {
    width: 100%;
  }
}

.form-item.form-type-bef-checkbox {
  position: relative;
}

label.option:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  right: 5px;
  height: 15px;
  width: 15px;
  background: white;
  border: 1px solid #2bb672;
  border-radius: 50%;
}

label.check-icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  right: 5px;
  height: 15px;
  width: 15px;
  background: url("/sites/all/themes/tripper/images/checkbox.svg");
}

#edit-field-continent-tid-wrapper {
  .views-widget {
    width: 180px;
  }
}

.form-item.form-type-radio.form-item-tid {
  position: relative;
}

.field--name-field-continent .field__item {
  margin-top: 10px;
}

.field--name-field-tags .field__items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 28px;
  margin-top: 13px;

   .field__item {
    background-color: $color--blue;
    margin-right: 10px;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    
    a {
      color: $color--white;
    }
  }
}


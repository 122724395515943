.tip-cat {
    padding: 10px 0;

    &.condensed {
        justify-content: space-between;

        @media screen and ( min-width: $br--laptop) {
            display: flex;
        }
    }

    &__countries {
        margin-right: 0;
        text-align: center;
        
        @media screen and ( min-width: $br--tab-portrait) {
            margin-right: 20px;
            text-align: left;
        }
    }

    select {
        padding: 4px;
    }
}

.subcat {
    &__nav {
        list-style: none;

        @media screen and ( min-width: $br--tab-portrait) {
            display: flex;
        }
    }
    
    &__title {
      margin: 0 10px;
    }
    
    &__item {
        padding: 5px;
        border-bottom: 1px solid $color--gray;
    }
    
    &__items {
        display: none;
        list-style: none;
        padding: 0;
        position: absolute;
        top: 24px;
        left: -2px;
        padding: 10px;
        z-index: 9999;
        width: 240px;
        background-color: $color--silver;

        input[type="checkbox"] {
            vertical-align: text-top;
        }
    }

    &__nav-item {

        padding: 5px;
        position: relative;
        font-size: 13.5px;
        margin-left: 5px;
        border: 1px solid;
        margin: 5.6%;

        @media screen and ( min-width: $br--tab-portrait) {
            padding: 2px;
            position: relative;
            font-size: 13.5px;
            margin-left: 5px;
            border: 1px solid;
            margin: 0;
            margin-top: 10px;
            margin-right: 10px;
        }

        @media screen and ( min-width: $br--laptop) {
            margin-top: 0;
        }
        

        &:hover .subcat__items {
            display: block;
        }
    }

    .sights-activities {
        border-color: #01534D;
    }

    .entertainment-culture {
        border-color: #EE7C96;
    }

    .sports {
        border-color: #018EBC;
    }

    .dining {
        border-color: #7C5EA5;
    }

    .airports {
        border-color: #A07457;
    }
}

.l-footer {
  background-color: $color--gray;
  color: $color--white;
}

.footer-content {
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  @include breakpoint(tablet-portrait) {
    display: flex;
    padding: 0;
  }
}

.l-footer .menu {
  padding: 0;
  margin: 0;
  list-style: none;
  
  li {
    list-style-type: none;
    list-style: none;
    display: block;
    margin-right: $padding--base * 2;
    line-height: 1.5;

    @include breakpoint(tablet-portrait) {
      display: inline;
      line-height: 1;
    }

    a {
      color: $color--white;

      &:hover,
      &.active-trail {
        color: $color--theme;
      }
    }
  }
}

.views-exposed-form .views-exposed-widget {
  float: left;
  padding: .5em 1em 0 0;
  width: 100%;

  @include breakpoint(tablet-portrait) {
    display: inline;
    width: 50%;
  }
}


@mixin breakpoint($point) {
    @if $point == desktop {
      @media (min-width: 1440px) { @content; }
    }
    @else if $point == laptop {
      @media (min-width: 1280px) { @content; }
    }
    @else if $point == tablet-landscape {
     @media (min-width: 1024px) { @content; }
    }
    @else if $point == tablet-portrait {
      @media (min-width: 768px)  { @content; }
   }
    @else if $point == mobile {
      @media (max-width: 375px)  { @content; }
    }
 }
 
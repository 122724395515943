// Remove default styles from masonry.
.masonry-item {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;

  img {
    width: 100%;
  }

  @media screen and ( min-width: $br--mobile ) {
    width: 50%;
  }

  @media screen and ( min-width: $br--laptop ) {
    width: 16.7%;
  }

  @media screen and ( min-width: $br--tab-portrait ) {
    width: 33%;
  }

  @media screen and ( min-width: $br--tab-landscape ) {
    width: 25%;
  }
}


.masonry-list .views-fieldset {
  position: relative;

  .field-content {
    margin: 2.5%;
  }

  .views-field-title {
    position: absolute;
    z-index: 2;
    bottom: 0;
    padding: $padding--base * 2;

    .field-content {
      margin: 0;
    }

    a {
      color: $color--white;
      text-shadow: 1px 1px 1px $color--gray;
      font-size: 0.8em;
      
      &:hover {
        color: $color--theme;
      }
    }
  }
}

.masonry-list .masonry-item:after {
  position: absolute;
  z-index: 1;
  content: "";
  display: inline-block;
  width: 95%;
  height: 11%;
  bottom: 9px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, black 200%);
  left: 7px;
}

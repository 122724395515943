.node__submitted {

  .profile__block {
    background-color: $color--light-gray;
    height: 167px;
    padding: 6px;
  }
  
  img {
    border: 4px solid $color--white;
    text-align: center;
    height: 120px;
    border-radius: 3px;
    margin: auto;
  }

  .submitted {
    padding: 0;
    margin: 0;
    font-size: 12px;
    text-align: center;
    width: 120px;
  }

  .username {
    color: $color--blue;
    font-weight: bold;
  }
}

.node--tip {
  .node__submitted {
    display: none;
  }
}


.node-type-tip {
  .node--tip {
    .node__submitted {
      display: block;
    }
  }
}

